<template>
  <v-row class="my-0 mx-auto">
    <v-col cols="12" class="pa-0 d-flex align-center justify-center">
      <v-card elevation="0" max-width="340" width="100%" rounded="lg" class="mx-auto my-4 px-md-0 px-2 text-center mt-md-16 mt-7">
        <v-card
          width="72"
          height="72"
          tile
          flat
          class="rounded-circle mx-auto d-flex align-center justify-center mb-7"
          :color="action ? 'text' : 'secondary'"
        >
          <img class="rounded-0" src="@/assets/icon/mail.svg" height="22" alt="lock" />
        </v-card>
        <div v-if="action == 'expired'">
          <div class="h2 mb-2">The link expired</div>
          <div class="h8 text--text mb-6">Sorry, but the email confirmation link is expired.</div>
          <!-- <v-btn class="d-block mx-auto mb-2" width="140" outlined>Reset confirmation</v-btn> -->
          <v-btn text to="/sign-in">Back to Log in</v-btn>
        </div>
        <div v-else-if="action == 'already'">
          <div class="h2 mb-2">The link already used</div>
          <div class="h8 text--text mb-6">Sorry, but this email confirmation link already used.</div>
          <v-btn text to="/sign-in">Back to Log in</v-btn>
        </div>
        <div v-else-if="action == 'invalid'">
          <div class="h2 mb-2">The link invalid</div>
          <div class="h8 text--text mb-6">Sorry, but this email confirmation link invalid.</div>
          <v-btn text to="/sign-in">Back to Log in</v-btn>
        </div>
        <div v-else>
          <div class="h2 mb-2">Email confirmation</div>
          <div class="h8 text--text mb-6">Please, confirm your email address</div>
          <!-- <div class="h8 text--text mb-6">Please, confirm your email address {{ email }}</div> -->
          <v-btn depressed @click="confirm" block large color="primary">CONFIRM</v-btn>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      password: '',
      action: false,
      error: [],
    };
  },
  methods: {
    async confirm() {
      this.error = [];
      const data = new Object();
      data.token = this.$route.query.t;
      await this.$store
        .dispatch('confirmEmail', data)
        .then(() => {
          sessionStorage.setItem('jwt_token', this.$route.query.t);
          setTimeout(() => {
            this.$router.push('/');
          }, 200);
        })
        .catch((e) => {
          this.error = e.response.data.error[0];
          if (this.error == 'token__expired') {
            this.action = 'expired';
          } else if (this.error == 'token__already_used') {
            this.action = 'already';
          } else if (this.error == 'token__invalid') {
            this.action = 'invalid';
          }
        });
    },
  },
  // computed: {
  //   email() {
  //     return jwt_decode(this.$route.query.t).ead;
  //   },
  // },
};
</script>
